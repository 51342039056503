export const tenant = {
    "xcfw": "https://api.xcfw.cn",
    "ncbest": "https://yunwei.jxncbest.cn:8005",
    "ncbest-jxzyy": "https://yunwei.jxncbest.cn:8010",
    "ncbest-ncswdx": "https://yunwei.jxncbest.cn:8011",
    "xcfw-test":"http://192.168.2.20:9005",
    // xcfw: "http://192.168.2.107:10005"
    // xcfw: "http://192.168.2.243:8005"
    // xcfw: "http://192.168.43.137:8005"
    // xcfw: "http://172.16.3.31:8005"
    // xcfw: "http://192.168.2.107:10005"
    // xcfw: "http://192.168.2.105:8005"
    //xcfw: "http://xcapi.v14.3dns.com.cn"
    // nc: "http://36.133.2.67:8121"
};

export const Progress = {
    "1": "待分配",
    "5": "待领取",
    "9": "待处理",
    "13": "处理中",
    "14": "挂起中",
    "17": "已完成",
};

export const map = {
    key: "686d79693d06ff9566d7f8fa8aec33f5",
    version: "2.0"
};

export default {
    tenant, map
};
