import axios from "axios";
import {tenant} from "@/plugin/profile";

const axiosResponseSuccessInterceptor = response => {
    if (response.data.status === 20000) {
        return response.data;
    }
    return Promise.reject(response.data);
}

const axiosResponseFailedInterceptor = error => {
    return !!error.response ? Promise.reject(error.response.data) : Promise.reject({
        status: 50400,
        desc: "网络异常",
        data: "网络异常"
    })
}

//axios.get(tenant.ncbest)

/**
 * 获取 axios 网络请求对象
 * @description 如果返回 undefined 则说明没有与当前 url 参数中匹配的租户 url 信息。
 */
export const buildAxiosInstance = () => {

   const baseURL = tenant[getUrlParam().tenant] || tenant['xcfw'];
   // const baseURL = tenant['xcfw'];
    if (!baseURL) {
        // 如果没有对应的租户信息则不返回 axios 对象
        return undefined;
    }
    // 构建 axios 对象并设置 response interceptor
    const axiosInstance = axios.create({baseURL, timeout: 6000 * 5});
    axiosInstance.interceptors.response.use(axiosResponseSuccessInterceptor, axiosResponseFailedInterceptor)
    return axiosInstance;
}

class AxiosProxy {

    axiosInstance
    getAxiosInstance = () => {
        this.axiosInstance = buildAxiosInstance()
        return this.axiosInstance
    }

    get = (url, params) => !!this.getAxiosInstance() ? this.axiosInstance.get(url, params) : Promise.reject(new Error('非法租户'))

    post = (url, params) => !!this.getAxiosInstance() ? this.axiosInstance.post(url, params) : Promise.reject(new Error('非法租户'))

    patch = (url, params) => !!this.getAxiosInstance() ? this.axiosInstance.patch(url, params) : Promise.reject(new Error('非法租户'))

    put = (url, params) => !!this.getAxiosInstance() ? this.axiosInstance.put(url, params) : Promise.reject(new Error('非法租户'))

    delete = (url, params) => !!this.getAxiosInstance() ? this.axiosInstance.delete(url, params) : Promise.reject(new Error('非法租户'))
}

const axiosProxyInstance = new AxiosProxy()

export default axiosProxyInstance
